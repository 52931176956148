import { useState, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import styles from "./header.module.sass";
import SettingsIcon from "./SettingsIcon";

const Header = () => {
  const [, setClickCount] = useState(0);
  const timeoutRef = useRef<number | null>(null);

  const copy = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigator.clipboard.writeText(token);
    }
  };

  const handleLogoClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setClickCount((prev) => {
      const newCount = prev + 1;
      if (newCount === 10) {

        copy();
        return 0;
      }
      return newCount;
    });

    timeoutRef.current = window.setTimeout(() => {
      setClickCount(0);
    }, 1000);
  };

  return (
    <header className={styles.header}>
      {/* <div className={styles.progressBarContainer}>
        <div className={styles.progressBarHead}>
          <span className={styles.period}>{"На сегодня >"}</span>
          <span className={styles.progress}>5/9</span>
        </div>
        <ProgressBar now={60} className={styles.progressBar} />
      </div> */}
      <div className={styles.logoContainer} onClick={handleLogoClick}>
        Planner
      </div>
      {/* <SettingsIcon className={styles.settingsIcon} /> */}
    </header>
  );
};

export default Header;