import React, { startTransition } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as TaskIcon } from './assets/tasks-icon.svg';
import { ReactComponent as NotesIcon } from './assets/notes-icon.svg';
import { ReactComponent as ReminderIcon } from './assets/reminder-icon.svg';
import classNames from 'classnames';
import styles from "./menu.module.sass";

const menuList = [
  {
    icon: <TaskIcon width={16} height={16} />,
    name: 'Задачи',
    link: '/',
  },
  {
    icon: <NotesIcon width={16} height={16} />,
    name: 'Заметки',
    link: '/notes',
  },
  {
    icon: <ReminderIcon width={16} height={16} />,
    name: 'Напоминания',
    link: '/reminders',
  },
]

export const Menu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (link: string) => {
    startTransition(() => {
      navigate(link);
    });
  };

  return (
    <div className={styles.menu}>
      {menuList.map(({name, icon, link}) => (
        <div
          key={name}
          className={classNames(styles.menuItem, {
            [styles.menuItemActive]: location.pathname === link,
          })}
          onClick={() => handleNavigation(link)}
        >
          {icon}
          <span className={styles.menuName}>{name}</span>
        </div>
      ))}
    </div>
  );
};