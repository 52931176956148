import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import "./editor.css";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useEffect, useState } from "react";
import { $getRoot, LexicalEditor } from "lexical";
import { useAppDispatch, useAppSelector } from "store/index";
import { createNote, noteInfoForUpdate, resetDataCreateNote, updateNotes } from "features/slices";
import { createNotesSelector, noteInfoForUpdateSelector } from "features/selectors";

function Placeholder() {
  return <div className="editor-placeholder">Введите текст...</div>;
}

export default function Editor() {
  const dispatch = useAppDispatch();
  const [editor, setEditor] = useState<LexicalEditor | null>(null);
  const [strings, setStrings] = useState({ title: "", subTitle: "" })
  const [isUpdateNote, setUpdateNote] = useState(false);
  const { result } = useAppSelector(createNotesSelector)
  const note = useAppSelector(noteInfoForUpdateSelector)
  const { _id, isFavorite } = result ?? {}

  useEffect(() => {
    if (note?.text) {
      setUpdateNote(true);
    }
  }, [note])

  useEffect(() => {
    if (note?.text && editor && isUpdateNote) {
      const parsedState = editor.parseEditorState(note.text);
      editor.setEditorState(parsedState);
    }
  }, [note, editor, isUpdateNote])

  useEffect(() => {
    return () => {
      dispatch(resetDataCreateNote())
      dispatch(noteInfoForUpdate(null))
    }
  }, []);

  useEffect(() => {
    if (!editor) return;

    const handleDebounce = setTimeout(() => {
      try {
        if (strings?.title) {
          const content = JSON.stringify(editor.getEditorState());
          if (note?.text !== content) {
            if (!_id && !note?._id) {
              dispatch(createNote({
                text: content ?? '',
                title: strings.title ?? '',
                subTitle: strings.subTitle ?? '',
              }))
            } else {
              dispatch(updateNotes({
                text: content ?? '',
                title: strings.title ?? '',
                subTitle: strings.subTitle ?? '',
                isFavorite: !!isFavorite,
                //@ts-ignore
                id: _id ?? note?._id,
              }))
            }
          }
        }
      } catch (error) {
        console.error("Ошибка обработки состояния редактора:", error);
      }
    }, 1000);

    return () => {
      clearTimeout(handleDebounce)
    };
  }, [editor?.getEditorState()]);

  useEffect(() => {
    return () => {
      if (editor) {
        try {
          const content = JSON.stringify(editor.getEditorState());
          if (note?.text !== content) {
            if (!_id && !note?._id) {
              dispatch(createNote({
                text: content ?? '',
                title: strings.title ?? '',
                subTitle: strings.subTitle ?? '',
              }))
            } else {
              dispatch(updateNotes({
                text: content ?? '',
                title: strings.title ?? '',
                subTitle: strings.subTitle ?? '',
                isFavorite: !!isFavorite,
                //@ts-ignore
                id: _id ?? note?._id,
              }))
            }
          }
        } catch (error) {
          console.error("Ошибка обработки состояния редактора:", error);
        }
      }
    }
  }, [editor])

  return (
    <div className="editor-container">
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <OnChangePlugin onChange={(_, editor) => {
          editor.update(() => {
            const root = $getRoot();
            const textContent = root.getTextContent(); // Получаем весь текст редактора
            const lines = textContent.split("\n"); // Разделяем текст по строкам
            const filteredLines = lines.filter((line) => line)
            const firstLine = filteredLines[0] || "";
            const secondLine = filteredLines[1] || "";
            setStrings({ title: firstLine, subTitle: secondLine })
          });

          setEditor(editor)
        }} />
      </div>
    </div>

  );
}
