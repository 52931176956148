import React from "react";

const MainPage = React.lazy(() => import(
  /* webpackChunkName: "page-main" */
  "../components/pages/Main/Main"
));

const Notes = React.lazy(() => import(
  /* webpackChunkName: "notes" */
  "../components/pages/Notes/Notes"
));

const Reminders = React.lazy(() => import(
  /* webpackChunkName: "reminders" */
  "../components/pages/Reminders/Reminders"
));

type TRoute = {
  path: string;
  Component: React.LazyExoticComponent<React.FC>;
};

export const routes: TRoute[] = [
  {
    path: "/",
    Component: MainPage,
  },
  {
    path: "/notes/",
    Component: Notes,
  },
  {
    path: "/reminders/",
    Component: Reminders,
  },
];