import { Route, Routes } from "react-router-dom";
import { Header, Menu } from "../components";

import { routes } from "./RouterList";

import 'bootstrap/dist/css/bootstrap.min.css';

const Router = () => {
  return (
    <div style={{maxWidth: 1280, width: '100%'}}>
      <Header />
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route
            path={path}
            key={path}
            element={
              // <Suspense fallback={<PageSkeleton />}>
              <Component />
              // </Suspense>
            }
          />
        ))}
        {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
      </Routes>
      <Menu />
    </div>

  );
};

export default Router;
