import { createSlice } from '@reduxjs/toolkit';
import { UIState } from '../../types/storeTypes';


const modulePrefix = 'ui';

const initialState: UIState = {
  isOpenCreateTaskModal: false,
  editTask: null,
  snacks: [],
  isOpenCreateNotesModal: false,
  note: null,
  isOpenCreateReminderModal: false,
  isOpenListRemindersModal: false,
  listRemindersData: null,
  reminderData: null,
  listInfo: null,
};

export const uiSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {
    toggleCreateTaskModal(state) {
      state.isOpenCreateTaskModal = !state.isOpenCreateTaskModal;
    },
    toggleEditTaskModal(state, action) {
      state.editTask = action.payload;
    },
    addSnack(state, action) {
      state.snacks = [...state.snacks, action.payload]
    },
    removeSnack(state, action) {
      state.snacks = state.snacks.filter(({ id }) => id !== action.payload)
    },
    toggleCreateNotesModal(state) {
      state.isOpenCreateNotesModal = !state.isOpenCreateNotesModal;
    },
    noteInfoForUpdate(state, action) {
      state.note = action.payload;
    },
    toggleCreateReminderModal(state) {
      state.isOpenCreateReminderModal = !state.isOpenCreateReminderModal;
    },
    toggleListRemindersModal(state) {
      state.isOpenListRemindersModal = !state.isOpenListRemindersModal;
    },
    listRemindersDataModal(state, action) {
      state.listRemindersData = action.payload;
    },
    reminderDataModal(state, action) {
      state.reminderData = action.payload;
    },
    setListInfo(state, action) {
      state.listInfo = action.payload;
    },
  }
});

const { actions, reducer } = uiSlice;

export const {
  toggleCreateTaskModal,
  addSnack,
  removeSnack,
  toggleEditTaskModal,
  toggleCreateNotesModal,
  noteInfoForUpdate,
  toggleCreateReminderModal,
  toggleListRemindersModal,
  listRemindersDataModal,
  reminderDataModal,
  setListInfo,
} = actions;

export default reducer;
