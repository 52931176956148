import { RootState as S } from "../../store";

export const userSelector = (store: S) => store.plannerData.user;
export const createTaskSelector = (store: S) => store.plannerData.createTask;
export const tasksSelector = (store: S) => store.plannerData.tasks;
export const updateTaskSelector = (store: S) => store.plannerData.updateTask;
export const deleteTaskSelector = (store: S) => store.plannerData.deleteTask;
export const createNotesSelector = (store: S) => store.plannerData.createNote;
export const notesSelector = (store: S) => store.plannerData.notes;
export const noteDeleteSelector = (store: S) => store.plannerData.noteDelete;
export const noteFavoriteSelector = (store: S) => store.plannerData.noteFavorite;
export const createReminderSelector = (store: S) => store.plannerData.createReminder;
export const remindersSelector = (store: S) => store.plannerData.reminders;
export const deleteRemindersSelector = (store: S) => store.plannerData.deleteReminders;
export const updateReminderSelector = (store: S) => store.plannerData.updateReminder;
