import {
  ICreateNotesRequest,
  ICreateReminderRequest,
  ICreateTaskRequest,
  IDeleteNotesRequest,
  IDeleteRemindersRequest,
  IDeleteTaskRequest,
  IFavoriteNotesRequest,
  IGetTasksRequest,
  ILoginRequest,
  IUpdateNotesRequest,
  IUpdateReminderRequest,
  IUpdateTaskRequest
} from "interfaces";
import { axiosExchange } from "./axios";

export enum ENDPOINTS {
  user = "auth/login",
  createTask = "tasks",
  tasks = "tasks",
  updateTask = "tasks",
  deleteTask = "tasks",
  createNote = "notes",
  updateNotes = "notes",
  notes = "notes",
  noteDelete = "notes",
  noteFavorite = "notes",
  createReminder = "reminders",
  reminders = "reminders",
  deleteReminders = "reminders",
  updateReminder = "reminders",
}

const user = async ({ ...request }: ILoginRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.user}`,
    {...request, timeZone: -new Date().getTimezoneOffset() / 60}
  );
}

const createTask = async ({ ...request }: ICreateTaskRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.createTask}/`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}

const updateTask = async ({ id, ...request }: IUpdateTaskRequest) => {
  return axiosExchange.patch(
    `/${ENDPOINTS.updateTask}/${id}`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}


const deleteTask = async ({ id }: IDeleteTaskRequest) => {
  return axiosExchange.delete(
    `/${ENDPOINTS.deleteTask}/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const tasks = async ({ dateStart, dateEnd }: IGetTasksRequest) => {
  const filteredParams = Object.entries({ dateStart, dateEnd })
    .filter(([_, value]) => value != null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const queryString = new URLSearchParams(filteredParams).toString();

  return axiosExchange.get(
    `/${ENDPOINTS.tasks}/?${queryString}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    }
  );
}

const createNote = async ({ ...request }: ICreateNotesRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.createNote}/`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}

const updateNotes = async ({ id, ...request }: IUpdateNotesRequest) => {
  return axiosExchange.put(
    `/${ENDPOINTS.createNote}/${id}`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}

const notes = async () => {
  return axiosExchange.get(
    `/${ENDPOINTS.notes}/`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    }
  );
}

const noteDelete = async ({ id }: IDeleteNotesRequest) => {
  return axiosExchange.delete(
    `/${ENDPOINTS.noteDelete}/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const noteFavorite = async ({ id }: IFavoriteNotesRequest) => {
  return axiosExchange.patch(
    `/${ENDPOINTS.noteFavorite}/${id}/favorite`,
    {},
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const createReminder = async ({ ...request }: ICreateReminderRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.createReminder}/`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}

const reminders = async () => {
  return axiosExchange.get(
    `/${ENDPOINTS.reminders}/`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    }
  );
}

const deleteReminders = async ({ ids }: IDeleteRemindersRequest) => {
  return axiosExchange.delete(
    `/${ENDPOINTS.deleteReminders}?${ids}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const updateReminder = async ({ id, ...request }: IUpdateReminderRequest) => {
  return axiosExchange.put(
    `/${ENDPOINTS.updateReminder}/${id}`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const service = {
  user,
  createTask,
  tasks,
  updateTask,
  deleteTask,
  createNote,
  updateNotes,
  notes,
  noteDelete,
  noteFavorite,
  createReminder,
  reminders,
  deleteReminders,
  updateReminder,
};

export default service;