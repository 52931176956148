import { FC, PropsWithChildren } from "react";
import styles from "./label.module.sass";
import classNames from "classnames";

interface ILabelProps {
  labelText: string;
  isRequired?: boolean;
  name?: string;
  additionalContainerClassName?: string;
}

const Label: FC<PropsWithChildren<ILabelProps>> = ({labelText, isRequired, children, name, additionalContainerClassName}) => {
  return (
    <div className={classNames(styles.labelContainer, additionalContainerClassName && additionalContainerClassName)}>
      <label className={styles.label} htmlFor={name} >{labelText} {isRequired ? <span>*</span> : ""}</label>
      {children}
    </div>
  )
}

export default Label;