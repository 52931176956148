import classNames from "classnames";
import React, { FC } from "react";
import styles from "./button.module.sass"

interface IButtonProps {
  // children: string | JSX.Element;
  additionalClassName?: string;
}

const Button: FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, additionalClassName, ...props}) => {
  return (
    <button
      className={classNames(styles.button, additionalClassName && additionalClassName)}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button;