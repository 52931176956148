import { LexicalComposer } from "@lexical/react/LexicalComposer";
import styles from "./noteDetail.module.sass";
import Editor from "../Editor/Editor";
import ExampleTheme from "../Editor/themes/ExampleTheme";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import ToolbarPlugin from "../Editor/plugins/ToolbarPlugin";


const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};


const NoteDetail = () => {
  return (
    <div className={styles.noteDetail}>
      <LexicalComposer initialConfig={editorConfig as any}>
        <div className={styles.noteDetailHeader}>
          <ToolbarPlugin />
        </div>
        <div className={styles.editorContainer}>
          <Editor />
        </div>
      </LexicalComposer>
    </div>
  );
};

export default NoteDetail;
