import { Modal as ModalBootstrap } from 'react-bootstrap';
import styles from "./modal.module.sass"
import classNames from 'classnames';

interface IModalProps {
  isShow: boolean;
  onHide: () => void;
  children: string | JSX.Element;
  title?: string;
  isHideHeader?: boolean;
  isFullVisModal?: boolean;
  isCenterSmallModal?: boolean;
}

const Modal = ({ isShow, onHide, children, title, isHideHeader, isFullVisModal, isCenterSmallModal }: IModalProps) => {
  return (
    <ModalBootstrap
      show={isShow}
      onHide={onHide}
      className={classNames(styles.modal, {
        [styles.modalFull]: isFullVisModal,
        [styles.centerSmallModal]: isCenterSmallModal,
      })}
    >
      {!isHideHeader && <ModalBootstrap.Header className={styles.modalHeader}>
        {!!title && <ModalBootstrap.Title className={styles.title}>{title}</ModalBootstrap.Title>}
        <button className={styles.closeModal} onClick={onHide} />
      </ModalBootstrap.Header>}
      <ModalBootstrap.Body className={styles.modalBody}>{children}</ModalBootstrap.Body>
    </ModalBootstrap>
  )
}

export default Modal