import { editTaskSelector, openCreateTaskSelector, toggleCreateTaskModal, toggleEditTaskModal } from "../../../features/index";
import { useAppDispatch, useAppSelector } from "../../../store/index";
import { Button, Modal } from "../../elements";
import { EventCalendar } from "./EventCalendar";
import styles from "./main.module.sass"
import TaskCreationForm from "./TaskCreationForm/TaskCreationForm";
import { ReactComponent as PlusIcon } from "./assets/plus-icon.svg";

const Main = () => {
  const dispatch = useAppDispatch();
  const isOpenCreateTaskModal = useAppSelector(openCreateTaskSelector)
  const editTaskInfo = useAppSelector(editTaskSelector);

  const toggleNewTaskModal = () => {
    dispatch(toggleCreateTaskModal())
    dispatch(toggleEditTaskModal(null))
  }

  return (
    <main className={styles.main}>
      <Button onClick={toggleNewTaskModal}>
        <div className={styles.buttonContent}>
          <PlusIcon width={16} height={16} />
          Создать задачу
        </div>
      </Button>
      <Modal isShow={isOpenCreateTaskModal} onHide={toggleNewTaskModal} title={editTaskInfo ? "Редактировать задачу" : "Создание задачи"}>
        <TaskCreationForm />
      </Modal>
      <EventCalendar />
    </main>
  )
}

export default Main;