import { parse, format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const parseGroupeName = (name: string) => {
  if (name === "favorite") {
    return "Избранное"
  }
  if (name === "today") {
    return "Сегодня"
  }
  if (name === "yesterday") {
    return "Вчера"
  }
  if (name === "last_week") {
    return "На этой неделе"
  }
  if (name === "two_weeks_ago") {
    return "2 недели назад"
  }

  const parsedDate = parse(name.includes('_') ? name.split('_').join(' ') : name, 'MMMM yyyy', new Date());
  return format(parsedDate, 'LLLL yyyy', { locale: ru }) ?? '';
}