import { RootState as S } from "../../store/index";

export const openCreateTaskSelector = (store: S) => store.uiData.isOpenCreateTaskModal;
export const snacks = (store: S) => store.uiData.snacks;
export const editTaskSelector = (store: S) => store.uiData.editTask;
export const openCreateNotesSelector = (store: S) => store.uiData.isOpenCreateNotesModal;
export const noteInfoForUpdateSelector = (store: S) => store.uiData.note;
export const openCreateReminderSelector = (store: S) => store.uiData.isOpenCreateReminderModal;
export const openListRemindersSelector = (store: S) => store.uiData.isOpenListRemindersModal;
export const listRemindersDataSelector = (store: S) => store.uiData.listRemindersData;
export const reminderDataSelector = (store: S) => store.uiData.reminderData;
export const listInfoSelector = (store: S) => store.uiData.listInfo;
