import { BrowserRouter, Route, Routes } from "react-router-dom";
import Router from './Router/Router';
import styles from "./app.module.sass"
import { useAppDispatch } from "store";
import { user } from "features";
import { useEffect } from "react";
import { LoaderScreen, Snacks } from "components";


const App = () => {
  const dispatch = useAppDispatch()

  const login = () => {
    dispatch(user({userData: window.Telegram.WebApp.initData}));
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //@ts-ignore
    window?.Telegram?.WebApp?.expand();
  }, [])

  return (
    <BrowserRouter basename="/">
      <div className={styles.layout}>
        <LoaderScreen />
        <Snacks />
        <Routes>
          <Route path="/*" element={<Router />} />
          {/* <Route path="/404" element={<NotFoundPage />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
