
export enum Reducers {
  planner = "plannerData",
  ui = "uiData"
}

export type TUser = {
  telegramId: number;
  username?: string;
  firstName: string;
  lastName?: string;
}

export type TTask = {
  user?: number;
  taskName: string,
  dateStart: number,
  dateEnd: number,
  comment?: string,
  reminderTime?: number,
  isOpen?: boolean,
  id: string,
}

export type TLoginInfo = {
  token: string;
  user: TUser;
}

export type TSnack = {
  id: string;
  text: string
  variant?: 'success' | 'warning' | 'error' | 'info';
}

export type TNote = {
  title: string;
  subTitle?: string;
  text: string;
  isFavorite: boolean;
  createdAt: Date;
  updatedAt: Date;
  _id: string;
}

export type TNotesGroups = {
  favorite: TNote[];
  last_week: TNote[];
  today: TNote[];
  two_weeks_ago: TNote[];
  yesterday: TNote[];
  [key: string]: TNote[];
}

export type TReminder = {
  reminderName: string,
  description?: string,
  dateStart?: number,
  reminderTime: number,
  frequency?: 'daily' | 'weekly' | 'monthly' | 'yearly',
  listName?: string,
  listId?: string,
  _id: string,
}

export type TRemindersGroups = {
  lists: {
    listName: string,
    listId: string;
    data: TReminder[]
  }[],
  nearestReminders: TReminder[],
  listsDetailInfo: {
    listName: string;
    listId: string;
  }[]
}