import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './notes.module.sass';
import { Button, Modal } from '../../elements';
import { useAppDispatch, useAppSelector } from 'store/index';
import { addSnack, noteDelete, noteFavorite, noteInfoForUpdate, resetDeleteDataNote, resetFavoriteDataNote, toggleCreateNotesModal } from 'features/slices';
import { noteDeleteSelector, noteFavoriteSelector, notesSelector, openCreateNotesSelector } from 'features/selectors';
import NoteDetail from './components/NoteDetail/NoteDetail';
import { ReactComponent as DeleteIcon } from "./assets/delete-icon.svg";
import { ReactComponent as FavoriteIcon } from "./assets/favorite-icon.svg";
import { ReactComponent as NotFavoriteIcon } from "./assets/not-favorite-icon.svg";
import { ReactComponent as PlusIcon } from "./assets/plus-icon.svg";
import { parseGroupeName } from './helpers';
import { TNote } from 'types/types';
import { Spinner } from 'react-bootstrap';

const Notes: React.FC = () => {
  const dispatch = useAppDispatch();
  const notesItemRef = useRef<HTMLDivElement>(null);

  const [swipedNoteId, setSwipedNoteId] = useState<string | null>(null);
  const [startX, setStartX] = useState<number | null>(null);
  const [isMouseDragging, setIsMouseDragging] = useState(false);
  const [hasControls, setHasControls] = useState(false)

  const isOpenModal = useAppSelector(openCreateNotesSelector);
  const { result } = useAppSelector(notesSelector);
  const { fetching: fetchingDelete, success: successDelete, message: messageDelete, result: resultDelete } = useAppSelector(noteDeleteSelector);
  const { fetching: fetchingFavorite, success: successFavorive, message: messageFavorive, result: resultFavorite } = useAppSelector(noteFavoriteSelector);

  useEffect(() => {
    if (!fetchingDelete && (messageDelete || resultDelete)) {
      setHasControls(false);
      dispatch(addSnack({
        variant: successDelete ? "success" : "error",
        text: messageDelete,
        id: swipedNoteId,
      }));
      setSwipedNoteId(null);
      setIsMouseDragging(false);
      dispatch(resetDeleteDataNote());
    }
  }, [fetchingDelete, messageDelete])

  useEffect(() => {
    if (!fetchingFavorite && (resultFavorite || messageFavorive)) {
      setHasControls(false);
      dispatch(addSnack({
        variant: successFavorive ? "success" : "error",
        text: !messageFavorive && successFavorive ?
          resultFavorite?.isFavorite ? "Заметка добавлена в избранное" : "Заметка больше не в избранном" : messageFavorive,
        id: swipedNoteId,
      }));
      setSwipedNoteId(null);
      setIsMouseDragging(false);
      dispatch(resetFavoriteDataNote());
    }
  }, [fetchingFavorite, messageFavorive])

  const toggleModal = () => {
    dispatch(toggleCreateNotesModal());
  };

  const handleStart = (clientX: number) => {
    setStartX(clientX);
    setSwipedNoteId(null);
  };

  const handleMove = (clientX: number, id: string) => {
    if (startX !== null) {
      const diffX = startX - clientX;
      if (diffX > 50) {
        setSwipedNoteId(id);
        setHasControls(true)
      } else if (diffX < -50) {
        setSwipedNoteId(null);
      }
    }
  };

  const handleTouchStart = (e: React.TouchEvent, id: string) => {
    handleStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent, id: string) => {
    handleMove(e.touches[0].clientX, id);
  };

  const handleMouseDown = (e: React.MouseEvent, id: string) => {
    setIsMouseDragging(true);
    handleStart(e.clientX);
  };

  const handleMouseMove = (e: React.MouseEvent, id: string) => {
    if (isMouseDragging) {
      handleMove(e.clientX, id);
    }
  };

  const handleMouseUp = () => {
    if (hasControls) {
      setIsMouseDragging(false);
      setStartX(null);
      setHasControls(true);
    }
  };

  const handleNote = (note: TNote) => {
    toggleModal();
    dispatch(noteInfoForUpdate(note));
  };

  const handleNoteDelete = (id: string) => {
    dispatch(noteDelete({ id }))
  }

  const handleNoteFavorite = (id: string) => {
    dispatch(noteFavorite({ id }));
  }

  return (
    <div className={styles.notes} onMouseUp={handleMouseUp}>
      <Button
        onClick={toggleModal}
        additionalClassName={styles.button}
      >
        <div className={styles.buttonContent}>
          <PlusIcon width={16} height={16} />
          Новая заметка
        </div>
      </Button>
      <div className={styles.notesList}>
        {Object.entries(result ?? {}).map(([dateGroup, notesInfo]) => {
          if (!notesInfo.length) {
            return null;
          }

          return (
            <div className={styles.notesGroup} key={dateGroup}>
              <span className={styles.groupDate}>{parseGroupeName(dateGroup)}</span>
              <div className={styles.notesItems}>
                {notesInfo.map((note) => (
                  <div
                    className={classNames(styles.notesItem, { [styles.swiped]: swipedNoteId === note._id })}
                    key={note._id}
                    ref={notesItemRef}
                  >
                    <div
                      className={styles.noteInfo}
                      onTouchStart={(e) => handleTouchStart(e, note._id)}
                      onTouchMove={(e) => handleTouchMove(e, note._id)}
                      onMouseDown={(e) => handleMouseDown(e, note._id)}
                      onMouseMove={(e) => handleMouseMove(e, note._id)}
                      onClick={(e) => {
                        if (!swipedNoteId) {
                          if (hasControls) {
                            setHasControls(false)
                          } else {
                            handleNote(note);
                            setIsMouseDragging(false);
                          }
                        }
                      }
                      }
                    >
                      <div className={styles.noteTitle}>{note.title}</div>
                      <div className={styles.noteText}>{note.subTitle}</div>
                    </div>
                    <div className={classNames(styles.notesControls, { [styles.notesControlsActive]: swipedNoteId === note._id })}>
                      <div className={classNames(styles.noteControl, styles.favoriteControl)} onClick={() => handleNoteFavorite(note._id)}>
                        {fetchingFavorite ? <Spinner animation="border" size='sm' color='red' /> :
                          note.isFavorite ? (
                            <FavoriteIcon width={18} height={18} />
                          ) : (
                            <NotFavoriteIcon width={18} height={18} />
                          )
                        }
                      </div>
                      <div className={classNames(styles.noteControl, styles.deleteControl)} onClick={() => handleNoteDelete(note._id)}>
                        {fetchingDelete ? <Spinner animation="border" size='sm' color='red' /> : <DeleteIcon width={22} height={22} />}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <Modal isShow={isOpenModal} onHide={toggleModal} isHideHeader isFullVisModal>
        <NoteDetail />
      </Modal>
    </div>
  );
};

export default Notes;