import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { IResponseMessage, IResponseSuccess } from "../interfaces";
import { Reducers } from "../types/types";

import { ENDPOINTS } from "../services";
import service from "../services/service";
import { loginUserThunk } from "./loginThinks";


// end(P)oints - enum с именами эндпоинтов
// (A)PI - сервисы

// api - группа сервисов
// serviceName - имя сервиса из P, он же имя будущего thunk'а

// sliceName - имя slice из enum Reducers
// actionName - имя action, на случай если два action используют один сервис
// callback - дополнительные действия

type EndpointsEnum = Record<string, string>

type API<Endpoints> = Record<
  keyof Endpoints,
  (request: any) => Promise<AxiosResponse>
>

type AnyRequest = Record<string, unknown>

type SuccessResponse =
  | IResponseSuccess<unknown>
  | IResponseMessage
  | string
  | object

type ResponseType =
  | 'data' // почти все запросы
  | 'string' // для infoEULAEng, infoEULA
  | 'guid' // для legalInformation, timesOfDelivery

  export const createThunk = <
  P extends EndpointsEnum,
  A extends API<P>
>(
  api: A,
  sliceName: Reducers
) => <
  E extends SuccessResponse,
  T extends AnyRequest
>(
  serviceName: keyof P,
  actionName?: string,
  callback?: (
    data: E,
    request?: T,
    thunkAPI?: unknown,
  ) => void,
  type: ResponseType = 'data'
) => createAsyncThunk<E, T>(
  `${sliceName}/${String(actionName ?? serviceName)}`,
  async (request, thunkAPI) => {
    try {
      const { data } = await api[serviceName](request);

      if (type === 'data' && !data) {
        return thunkAPI.rejectWithValue(
          data?.Message ?? `Ошибка загрузки ${sliceName}/${String(serviceName)}`
        );
      }

      if (callback) callback(data, request, thunkAPI);

      return data as E;
    } catch (error: unknown) {
      let message = "Неизвестная ошибка";

      if (typeof error === "string") {
        message = error;
      } else if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const loginResult = await thunkAPI.dispatch(loginUserThunk());

          if (loginResult.meta.requestStatus === 'fulfilled') {
            try {
              const { data } = await api[serviceName](request);

              if (callback) callback(data, request, thunkAPI);

              return data as E;
            } catch (retryError) {
              return thunkAPI.rejectWithValue("Ошибка при повторном запросе.");
            }
          } else {
            message = "Ошибка авторизации: повторный логин не удался.";
          }
        } else {
          message = error.message;
        }
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const createShopThunk = createThunk<
  typeof ENDPOINTS,
  typeof service
>(service, Reducers.planner);
