import { useAppDispatch } from "store/index";
import styles from "./backArrow.module.sass"
import { toggleCreateNotesModal } from "features/slices";
import { ReactComponent as BackIcon } from "./assets/back-icon.svg";

const BackArrow = () => {
  const dispatch = useAppDispatch();
  const toggleModal = () => dispatch(toggleCreateNotesModal());

  return (
    <div className={styles.backContainer} onClick={toggleModal}>
      <BackIcon width={14} height={14} />
      <span className={styles.backText}>Заметки</span>
    </div>
  )
}

export default BackArrow