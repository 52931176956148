import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer";
import { TLoginInfo } from "types/types";
import {
  ICreateNoteskResponse,
  ICreateNotesRequest,
  ICreateReminderRequest,
  ICreateReminderResponse,
  ICreateTaskRequest,
  ICreateTaskResponse,
  IDeleteNotesRequest,
  IDeleteNotesResponse,
  IDeleteRemindersRequest,
  IDeleteRemindersResponse,
  IDeleteTaskRequest,
  IDeleteTaskResponse,
  IFavoriteNotesRequest,
  IFavoriteNotesResponse,
  IGetNoteskResponse,
  IGetNotesRequest,
  IGetRemindersRequest,
  IGetRemindersResponse,
  IGetTasksRequest,
  IGetTasksResponse,
  ILoginRequest,
  ILoginResponse,
  IUpdateNotesRequest,
  IUpdateNotesResponse,
  IUpdateReminderRequest,
  IUpdateReminderResponse,
  IUpdateTaskRequest,
  IUpdateTaskResponse,
} from "../../interfaces/interfaces";
import { PlannerState } from "../../types/storeTypes";

import { initDetail, initDto, onFulfilledDataReducer, onFulfilledReducer, onPendingReducer, onRejectedReducer } from "../reducers";
import { createShopThunk } from "../thunks";


const initialState: PlannerState = {
  user: { ...initDetail },
  createTask: { ...initDetail },
  tasks: { ...initDetail },
  updateTask: { ...initDetail },
  deleteTask: { ...initDetail },
  createNote: { ...initDetail },
  notes: { ...initDetail },
  updateNotes: { ...initDetail },
  noteDelete: { ...initDetail },
  noteFavorite: { ...initDetail },
  createReminder: { ...initDetail },
  reminders: { ...initDetail },
  deleteReminders: { ...initDetail },
  updateReminder: { ...initDetail },
};

type StateDraft = WritableDraft<PlannerState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const user = createShopThunk<
  ILoginResponse,
  ILoginRequest
>("user");

export const createTask = createShopThunk<
  ICreateTaskResponse,
  ICreateTaskRequest
>("createTask");

export const tasks = createShopThunk<
  IGetTasksResponse,
  IGetTasksRequest
>("tasks");

export const updateTask = createShopThunk<
  IUpdateTaskResponse,
  IUpdateTaskRequest
>("updateTask");

export const deleteTask = createShopThunk<
  IDeleteTaskResponse,
  IDeleteTaskRequest
>("deleteTask");

export const createNote = createShopThunk<
  ICreateNoteskResponse,
  ICreateNotesRequest
>("createNote");

export const updateNotes = createShopThunk<
  IUpdateNotesResponse,
  IUpdateNotesRequest
>("updateNotes");

export const notes = createShopThunk<
  IGetNoteskResponse,
  IGetNotesRequest
>("notes");

export const noteDelete = createShopThunk<
  IDeleteNotesResponse,
  IDeleteNotesRequest
>("noteDelete");

export const noteFavorite = createShopThunk<
  IFavoriteNotesResponse,
  IFavoriteNotesRequest
>("noteFavorite");

export const createReminder = createShopThunk<
  ICreateReminderResponse,
  ICreateReminderRequest
>("createReminder");

export const reminders = createShopThunk<
  IGetRemindersResponse,
  IGetRemindersRequest
>("reminders");

export const deleteReminders = createShopThunk<
  IDeleteRemindersResponse,
  IDeleteRemindersRequest
>("deleteReminders");

export const updateReminder = createShopThunk<
  IUpdateReminderResponse,
  IUpdateReminderRequest
>("updateReminder");

// =-=-=-=-=-=-=-=-=-=-=-=-= SLICE =-=-=-=-=-=-=-=-=-=-=-=-=

export const generalSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    resetCreateTask(state) {
      state.createTask = { ...initDetail }
    },
    updateTasks(state, action) {
      state.tasks.result = action.payload
    },
    resetDataDeleteTask(state) {
      state.deleteTask = { ...initDetail }
    },
    resetDataCreateNote(state) {
      state.createNote = { ...initDetail }
    },
    resetDeleteDataNote(state) {
      state.noteDelete = { ...initDetail }
    },
    resetFavoriteDataNote(state) {
      state.noteFavorite = { ...initDetail }
    },
    resetCreateReminder(state) {
      state.createReminder = { ...initDetail }
    },
    resetDeleteReminder(state) {
      state.deleteReminders = { ...initDetail }
    },
    resetUpdateReminder(state) {
      state.updateReminder = { ...initDetail }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(user.pending, onPendingReducer<StateDraft>("user"))
      .addCase(user.rejected, onRejectedReducer<StateDraft>("user"))
      .addCase(user.fulfilled, onFulfilledReducer<StateDraft>("user"))

      .addCase(createTask.pending, onPendingReducer<StateDraft>("createTask"))
      .addCase(createTask.rejected, onRejectedReducer<StateDraft>("createTask"))
      .addCase(createTask.fulfilled, onFulfilledReducer<StateDraft>("createTask", (action, state) => {
        if (action.payload && state.tasks.result) {
          state.tasks.result = [...state.tasks.result, action.payload]
        }
      }))

      .addCase(updateTask.pending, onPendingReducer<StateDraft>("updateTask"))
      .addCase(updateTask.rejected, onRejectedReducer<StateDraft>("updateTask"))
      .addCase(updateTask.fulfilled, onFulfilledReducer<StateDraft>("updateTask"))

      .addCase(tasks.pending, onPendingReducer<StateDraft>("tasks"))
      .addCase(tasks.rejected, onRejectedReducer<StateDraft>("tasks"))
      .addCase(tasks.fulfilled, onFulfilledReducer<StateDraft>("tasks"))

      .addCase(deleteTask.pending, onPendingReducer<StateDraft>("deleteTask"))
      .addCase(deleteTask.rejected, onRejectedReducer<StateDraft>("deleteTask"))
      .addCase(deleteTask.fulfilled, onFulfilledReducer<StateDraft>("deleteTask"))

      .addCase(createNote.pending, onPendingReducer<StateDraft>("createNote"))
      .addCase(createNote.rejected, onRejectedReducer<StateDraft>("createNote"))
      .addCase(createNote.fulfilled, onFulfilledReducer<StateDraft>("createNote", (action, state) => {
        if (action.payload && state.notes.result) {
          if (action.payload && state.createNote.result) {
            state.createNote.result = state.createNote.result
            if (state.notes.result) {
              const newObj = { ...state.notes.result };
              const createNote = state.createNote.result;

              Object.keys(newObj).forEach((key) => {
                newObj[key] = newObj[key].filter(note => note._id !== createNote._id);
              });

              const targetGroup = createNote.isFavorite ? 'favorite' : 'today';

              newObj[targetGroup] = [createNote, ...newObj[targetGroup]]
              state.notes.result = newObj;
            }
          }
        }
      }))

      .addCase(updateNotes.pending, onPendingReducer<StateDraft>("updateNotes"))
      .addCase(updateNotes.rejected, onRejectedReducer<StateDraft>("updateNotes"))
      .addCase(updateNotes.fulfilled, onFulfilledReducer<StateDraft>("updateNotes", (action, state) => {
        if (action.payload && state.updateNotes.result) {
          state.updateNotes.result = state.updateNotes.result
          if (state.notes.result) {
            const newObj = { ...state.notes.result };
            const updatedNote = state.updateNotes.result;

            Object.keys(newObj).forEach((key) => {
              newObj[key] = newObj[key].filter(note => note._id !== updatedNote._id);
            });

            const targetGroup = updatedNote.isFavorite ? 'favorite' : 'today';

            newObj[targetGroup] = [updatedNote, ...newObj[targetGroup]]
            state.notes.result = newObj;
          }
        }
      }))

      .addCase(notes.pending, onPendingReducer<StateDraft>("notes"))
      .addCase(notes.rejected, onRejectedReducer<StateDraft>("notes"))
      .addCase(notes.fulfilled, onFulfilledReducer<StateDraft>("notes"))


      .addCase(noteDelete.pending, onPendingReducer<StateDraft>("noteDelete"))
      .addCase(noteDelete.rejected, onRejectedReducer<StateDraft>("noteDelete"))
      .addCase(noteDelete.fulfilled, onFulfilledReducer<StateDraft>("noteDelete", (action, state) => {
        if (action.payload && state.notes.result) {
          state.noteDelete.result = state.updateNotes.result
          if (state.notes.result) {
            const newObj = { ...state.notes.result };
            const updatedNote = { ...action.payload.note };

            Object.keys(newObj).forEach((key) => {
              newObj[key] = newObj[key].filter(note => note._id !== updatedNote._id);
            });

            state.notes.result = newObj;
          }
        }
      }))

      .addCase(noteFavorite.pending, onPendingReducer<StateDraft>("noteFavorite"))
      .addCase(noteFavorite.rejected, onRejectedReducer<StateDraft>("noteFavorite"))
      .addCase(noteFavorite.fulfilled, onFulfilledReducer<StateDraft>("noteFavorite", (action, state) => {
        if (action.payload && state.noteFavorite.result) {
          state.noteFavorite.result = state.noteFavorite.result
          if (state.notes.result) {
            const newObj = { ...state.notes.result };
            const updatedNote = state.noteFavorite.result;

            Object.keys(newObj).forEach((key) => {
              newObj[key] = newObj[key].filter(note => note._id !== updatedNote._id);
            });

            const targetGroup = updatedNote.isFavorite ? 'favorite' : 'today';

            newObj[targetGroup] = [updatedNote, ...newObj[targetGroup]]
            state.notes.result = newObj;
          }
        }
      }))

      .addCase(createReminder.pending, onPendingReducer<StateDraft>("createReminder"))
      .addCase(createReminder.rejected, onRejectedReducer<StateDraft>("createReminder"))
      .addCase(createReminder.fulfilled, onFulfilledReducer<StateDraft>("createReminder"))

      .addCase(reminders.pending, onPendingReducer<StateDraft>("reminders"))
      .addCase(reminders.rejected, onRejectedReducer<StateDraft>("reminders"))
      .addCase(reminders.fulfilled, onFulfilledReducer<StateDraft>("reminders"))

      .addCase(deleteReminders.pending, onPendingReducer<StateDraft>("deleteReminders"))
      .addCase(deleteReminders.rejected, onRejectedReducer<StateDraft>("deleteReminders"))
      .addCase(deleteReminders.fulfilled, onFulfilledReducer<StateDraft>("deleteReminders"))

      .addCase(updateReminder.pending, onPendingReducer<StateDraft>("updateReminder"))
      .addCase(updateReminder.rejected, onRejectedReducer<StateDraft>("updateReminder"))
      .addCase(updateReminder.fulfilled, onFulfilledReducer<StateDraft>("updateReminder"))
  }
});

const { actions, reducer } = generalSlice;

export const {
  resetCreateTask,
  updateTasks,
  resetDataDeleteTask,
  resetDataCreateNote,
  resetDeleteDataNote,
  resetFavoriteDataNote,
  resetCreateReminder,
  resetDeleteReminder,
  resetUpdateReminder,
} = actions;

export default reducer;
