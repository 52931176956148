import classNames from "classnames";
import { useState } from "react";
import arrowIcon from "./assets/arrow-icon.svg"
import styles from "./select.module.sass";

interface ISelectProps {
  value: string;
  options: { label: string, value: string | number }[];
  onChange: (value: string | number) => void;
  classNameContainer?: string;
  isDisabled?: boolean;
}

const Select = ({ value, options, onChange, classNameContainer, isDisabled }: ISelectProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleSelected = (option: string | number) => {
    setOpen(false);
    onChange(option)
  }

  return (
    <div className={classNames(styles.selectContainer, classNameContainer && classNameContainer)}>
      <div className={classNames(styles.select, {
        [styles.disabled]: isDisabled,
        [styles.selectActive]: isOpen,
      })} onClick={() => {
        if (!isDisabled) setOpen((prev) => !prev)
      }}>
        <span className={styles.selectedText}>{value}</span>
        <img src={arrowIcon} alt={"Открыть список"} width={24} height={24} />
      </div>
      {isOpen &&
        <div className={styles.selectList}>
          {options.map((option) => (
            <div className={classNames(styles.selectOption, {
              [styles.selectOptionActive]: value === option.label
            })} onClick={() => handleSelected(option.value)} key={option.label + option.value}>{option.label}</div>
          ))}
        </div>
      }
      {isOpen && <div className={styles.overlay} onClick={() => setOpen(false)}/>}
    </div>
  )
}

export default Select